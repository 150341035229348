import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { SlideDown } from '../../../animation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [SlideDown]
})
export class SideNavComponent implements OnInit {

  @Input() navItems: any[];
  @Output() logoClickEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('sidebar') sidebar: ElementRef;
  isSidebarCollapsed = false;

  constructor(private ngZone: NgZone, private router: Router) { }

  ngOnInit() { }

  onNavItemClicked(index: number, childIndex?: any) {
    if (this.isSidebarCollapsed) {
      return;  // Exit if sidebar is collapsed
    }

    let nav = this.navItems[index];
    console.log(nav);
    this.navItems.map(item => {
      item.isActive = false;
      item.isOpen = false;
      if (item.children && item.children.length) {
        item.children = item.children.map((c: any) => {
          c.isActive = false;
          return c;
        });
      }
      return item;
    });

    this.navItems[index].isActive = true;

    if (nav.children) {
      this.navItems.map(item => {
        item.isOpen = false;
        return item;
      });
      this.navItems[index].isOpen = !this.navItems[index].isOpen;
    }

    if (childIndex > -1) {
      this.navItems[index].children[childIndex].isActive = true;
      this.goto(this.navItems[index].children[childIndex].url);
    } else {
      this.goto(this.navItems[index].url);
    }
  }

  goto(route: any) {
    this.ngZone.run(() => {
      this.router.navigate([route]);
    });
  }

  navEvent(event: any) {
    this.logoClickEvent.emit(event);
  }

  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  isOpen = false;

  toggleNav() {
    this.isOpen = !this.isOpen;
  }

  toggleSidebarWidth() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.sidebar.nativeElement.style.width = this.isSidebarCollapsed ? '30px' : '180px';

    if (this.isSidebarCollapsed) {
      this.closeAllItems();
    }
  }

  closeAllItems() {
    this.navItems.forEach(item => {
      item.isActive = false;
      item.isOpen = false;
      if (item.children && item.children.length) {
        item.children.forEach((child: any) => {
          child.isActive = false;
        });
      }
    });
  }
}
