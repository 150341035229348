<app-spinner></app-spinner>
<notifier-container></notifier-container>
<div class=" h-100 d-flex">
  <div [@slideInOut]="sideNavState">
    <app-side-nav [navItems]="navItems" (logoClickEvent)="logoClickEvent($event)"></app-side-nav>
  </div>
  <div class="flex-grow-1 d-flex flex-column">
    <app-header [userName]="userName"></app-header>
    <!-- <button class="btn btn-primary m-2" (click)="toggleSideNav()">Toggle Side Nav</button> -->
    <div class="overflow-auto flex-grow-1 p-1 animated fadeIn">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>