import { DefaultController } from '../controllers/default-layout.controller';
import { ApiService } from '../../../service/api.service';
import { DefaultView } from './default-layout.view';

export class DefaultViewModel implements DefaultController {
    getProfile(token : any) {
       this.apiService.get('user' , token ).then(resp => {
           if(resp['status']) {
                this.mvDefaultView.OnProfileResponse(resp['data'])
           }
       })
    }

    public apiService 
    public mvDefaultView 

    constructor(_apiService : ApiService , _mvDefaultView : DefaultView) {
        this.apiService = _apiService
        this.mvDefaultView = _mvDefaultView
    }

}