<nav class="navbar navbar-expand-lg navbar-light bg-primary shadow">
  <div class="container-fluid">
    
    <!-- Navbar links -->
    <div class="d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
      <ul class="navbar-nav">
       
        <div ngbDropdown class="d-inline-block dropdown-float">
          <button type="button" class="btn text-white" id="dropdownBasic1" ngbDropdownToggle>
              {{userName}}
          </button>
          <div ngbDropdownMenu >
            <button ngbDropdownItem (click)="logout()">Logout</button>
          </div>
        </div>
      </ul>
    </div>
  </div>
</nav>