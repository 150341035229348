import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  userName : string
  constructor(private ngZone : NgZone, private router:Router) { }

  ngOnInit() {
  }


  logout() {

    localStorage.clear()

    // this.sessionService.changeDate("");
    this.ngZone.run(() => { 
      this.router.navigate([''])
    })
  }
}
