<div class="side-nav p-2 h-100">
  <div #sidebar class="sidebar">
    <div class="profile-container  position-relative hover-state">
      <div class="profile">
        <div class="company-profile d-flex align-items-center">
          <img src="assets/logo2.png" (click)="toggleSidebarWidth()" class="logo" alt="">
          <div class="user-details align-items-center pl-2">
            <div>Tallent Bill</div>
            <!-- <div>7373170701</div> -->
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let nav of navItems; let i = index" [ngClass]="{'active': nav.isActive, 'in-active': !nav.isActive, 'is-open': nav.isOpen}">
      <div (click)="onNavItemClicked(i)" class="d-flex item p-1 hover align-items-center w-100 text-decoration-none cursor-pointer" [class.disabled]="isSidebarCollapsed">
        <div class="icon pr-3">
          <img [src]="nav.icon" class="icon" />
        </div>
        <div class="w-100 d-flex justify-content-between">
          {{ nav.name }}
          <div *ngIf="nav.children && nav.children.length > 0" class="d-flex align-items-center justify-content-center me-3">
            <img [ngClass]="nav.isOpen ? 'icon-open' : 'icon-close'" [src]="nav.isOpen ? 'assets/icons/down.svg' : 'assets/icons/right.svg'" />
          </div>
        </div>
      </div>
      <div *ngIf="nav.children && nav.children.length > 0 && nav.isOpen" class="display-block sub-menu w-100">
        <div *ngFor="let c of nav.children; let ci = index" [ngClass]="{'active': c.isActive, 'in-active': !c.isActive}" (click)="onNavItemClicked(i, ci)" class="d-flex item hover py-1 align-items-center w-100 text-decoration-none cursor-pointer slide-down">
          <div class="icon "></div>
          <div class="w-100 pl-4 d-flex justify-content-between">
            {{ c.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
