import { LoginController } from '../controllers/login.controller';
import { LoginView } from './login.view';
import { ApiService } from '../../../service/api.service';

export class LoginViewModel implements LoginController {
    getLogin(data: any) {
        this.mvLoginView.showSpinner()
        this.apiService.authentication('/user/login', data).then(data => {
            this.mvLoginView.hideSpinner()
            if (data['status']) {
            
                if (data['user']['isDisabled']) {
                    this.mvLoginView.OnUserDisabled(data)
                  
                }
                else {
                 this.mvLoginView.OnLoginResponse(data)
               
                }

            }
            else {
                this.mvLoginView.OnFailed(data['message'])
            }
        }).catch(err => {
            this.mvLoginView.hideSpinner()
            this.mvLoginView.OnNetworkError(err)
        })
    }

    public apiService: any
    public mvLoginView: any
    constructor(_apiService: ApiService, _mvLoginView: LoginView) {
        this.apiService = _apiService
        this.mvLoginView = _mvLoginView
    }
}