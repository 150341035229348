import { FormGroup } from '@angular/forms';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  apiUrl:  "https://cloud.tallentbill.com/api/" ,
  tallentPayApiUrl:  "https://cloud.tallentbill.com/api/tallent_pay/v1/" ,
  s3Url : "https://tallentbill-server.s3.ap-south-1.amazonaws.com/",
  tpayS3Url : "https://tallentpay.s3.ap-south-1.amazonaws.com/",
  header :  function getHeaders() {
    let session =   JSON.parse(localStorage.getItem("user_datas"))
    return this.headers = {
      headers: {
          'Authorization': session.token
      }
  }
  },
  appendImageToFormData: function appendImageToFormData(element: any, key: string, formData: FormData): FormData {
    formData.delete(key)
    formData.append(key, element.target.files[0], element.target.files[0].name);

    return formData
  },
  appendDataToFormData: function appendDataToFormData(form: FormGroup, formData: FormData): FormData {
    for (let elem of Object.keys(form.value)) {
      if (elem != "modelImage") {
        formData.delete(elem)
        console.log(elem, form.value[elem])
        formData.append(elem, form.value[elem])
      }
    }

    return formData
  },
  timeout : 20000
};

