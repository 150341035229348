import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe, } from '@angular/common';


import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';


import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];


// Import routing module
import { AppRoutingModule } from './app.routing';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// Import 3rd party components
import { ChartsModule } from 'ng2-charts';
import { FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AddStoreComponent } from './views/store/add-store/add-store.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { SpinnerComponent } from './views/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotifierModule } from 'angular-notifier';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideNavComponent } from './containers/default-layout/side-nav/side-nav.component';
import { HeaderComponent } from './containers/default-layout/header/header.component';
// import { DatePipe } from './views/pipes/date.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgTemplateNameDirective } from './directives/ng-template-name.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from './base/base.component';
import { BaseInterceptor } from './base.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'top'
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxSpinnerModule,
    ChartsModule,
    NgbModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    SpinnerComponent,
    ForgotPasswordComponent,
    SideNavComponent,
    HeaderComponent,
    BaseComponent,
  ],
  providers: [
    SpinnerComponent,
    DatePipe,
    FormBuilder,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent],
  exports : [TranslateModule]
})
export class AppModule { }
