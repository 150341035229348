import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check if the request URL ends with '.json'
    if (request.url.endsWith('.json')) {
      return next.handle(request);
    }

    const user = localStorage.getItem('user_datas') ? JSON.parse(localStorage.getItem('user_datas')) : null;

    // Clone the request to add the new header, if user and user.token are available
    const clonedRequest = user && user.token
      ? request.clone({
          headers: request.headers.set('Authorization', `${user.token}`)
        })
      : request;

    return next.handle(clonedRequest);
  }
}
