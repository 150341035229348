import { Component, NgZone, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NotifierService } from 'angular-notifier';
import { LoginView } from './views/login.view';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginViewModel } from './views/login.viewmodel';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements LoginView,AfterViewInit { 
  loginParams : FormGroup
  session : any
  forgot : any;
  private readonly notifier: NotifierService;
  constructor(public formBuilder : FormBuilder  ,public spinner : SpinnerComponent, public apiService : ApiService , public router : Router, public ngZone : NgZone,notifierService: NotifierService) {
    this.session = localStorage.getItem("token")
    
    if(this.session != null) {
      this.router.navigate(['/dashboard'])
    }
    this.loginParams = formBuilder.group({
      emailId: ['' , Validators.required],
      password : ['' , Validators.required]
    })

    this.notifier = notifierService
   
  
  }

  public mvLoginViewModel : LoginViewModel
  ngAfterViewInit() :void {
    this.mvLoginViewModel = new LoginViewModel(this.apiService , this)
  
  }
  cancel(event) {
    this.forgot = event;
  }
  loginResponse : any;
  login(){

    if(this.loginParams.valid) {
     
      this.mvLoginViewModel.getLogin(this.loginParams.value)
     
    }
    else {
      this.notifier.notify('error' , "Please fill all the fields")
    }
  }

  OnLoginResponse(response) {
    // console.log(response)
    localStorage.setItem("token", response['token'])
    localStorage.setItem("user_datas", JSON.stringify(response))
    this.ngZone.run(() => {
      this.router.navigate(['/dashboard'])
    })
  }

  OnFailed(err) {
    this.notifier.notify("error" , err)
  }

  OnNetworkError(err) {
    if(err instanceof HttpErrorResponse) {
      this.notifier.notify("warning" , "Server Connection Lost.. Please Check your internet connection")
    }
    else {
      this.notifier.notify("error" , "Unknown error")

    }
  }

  OnUserDisabled (data) {
    if (data['user']['role'] == "branch") {
      this.notifier.notify('warning', "Your account is currently disabled please contact your store owner")
  }
  else {
      this.notifier.notify('warning', "Your account is currently disabled please contact admin")
  }
  }
  showSpinner() {
    this.spinner.showSpinner()
  }
  hideSpinner() {
    this.spinner.hideSpinner()

  }
}
