export const superAdminNavItems = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: "assets/icons/dashboard.svg",

  },
  {
    name: 'Store',
    url: '/store',
    icon: "assets/icons/store.svg",
    children: [
      {
        name: 'Add Store',
        url: '/store/add-store',
        icon: 'icon-cart'
      },
      {
        name: 'View Store',
        url: '/store/view-store',
        icon: 'icon-cart'
      },
    ]
  },
  {
    name: 'Manager',
    url: '/store',
    icon: "assets/icons/user.svg",
    children: [
      // {
      //   name: 'Add Business Manager',
      //   url: '/store/add-business-manager'
      // },
      {
        name: 'View Managers',
        url: '/store/view-business-manager'
      },
    ]
  }



];
