import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../service/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {

  @Output() forgot :  EventEmitter<any> = new EventEmitter()
  email : any;
  session : any;
  constructor(public apiService : ApiService) { 
   
  }

  ngOnInit() {
  }

  cancel() {
    this.forgot.emit(false)
  }

  forgotPasswordResponse : any

  sendPassword () {
    this.apiService.forgotPassword('/user/forgot-password',{email : this.email}).then(forgotResponse => {
       // // console.log(forgotResponse)
      this.forgotPasswordResponse = forgotResponse

      if(this.forgotPasswordResponse.status) {
        alert(this.forgotPasswordResponse.message)
      }
    })
  }

}
