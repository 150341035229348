<div class="animated fadeIn">
    <h1>Forgot Password ?</h1>
<p class="text-muted">Please enter your mail id</p>
<div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text"><i class="fa fa-envelope"></i></span>
  </div>
  <input type="text" class="form-control" placeholder="Email" [(ngModel)]="email" autocomplete="username" required>
</div>


<div>

  <div class=" text-right text-white">

    <button  (click)="cancel()" class="btn btn-primary" style="text-align:center;border-radius: 5px;" style="color: white">
      Cancel
    </button> &nbsp;
    <button (click)="sendPassword()" class="btn btn-primary" style="text-align:center;border-radius: 5px;" style="color: white">
      Send
    </button>
  </div>
</div>

</div>