import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
    if(window){
      window.console.log=function(){};
    }
  
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err =>   console.log(err));
