<div class="animated fadeIn">

<div style="opacity:0.5;background-color: black" class="w-100 h-100">
      
</div>
<ngx-spinner

size = "large"
color = "#001780"
type = "ball-scale-ripple-multiple"
fullScreen = "true"
>
</ngx-spinner>

</div>